import {
  GuaranteePolicy,
  GuaranteeType,
} from 'src/core/Shared/domain/Policies.model'
import { Price } from 'src/core/Shared/domain/Price.model'
import { FormatOptions } from 'src/ui/hooks/usePriceFormatter'

type GuaranteeDetails = {
  token: GuaranteeDetailsToken
  options?: GuaranteeDetailsOptions
}

type GuaranteeDetailsToken =
  | 'not-charge'
  | 'payment-method'
  | 'charge-in-hotel'
  | 'deposit'
  | 'deposit-partial-days'
  | 'deposit-partial-percentage'
  | 'with-extras'
  | 'without-extras'
  | 'pending-partial'
  | 'with-extras_v2'
  | 'without-extras_v2'
  | 'deposit_v2'

type GuaranteeDetailsOptions = {
  price?: string
  pending?: string
  charged?: string
  value?: number
  percentage?: number
}

export const calculateDetails = (
  guarantee: GuaranteePolicy,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasToShowPrices: boolean,
  hasIncludedExtras: boolean,
): GuaranteeDetails[] => {
  if (hasToShowPrices) {
    return buildAbsoluteDetails(guarantee, formatPrice, hasIncludedExtras)[
      guarantee.type.key
    ]
  } else {
    return buildPartialDetails(guarantee)[guarantee.type.key]
  }
}

/** Claves de traducción con detalles con importes */
const buildAbsoluteDetails = (
  { deposit, pending, relative }: GuaranteePolicy,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasIncludedExtras: boolean,
): {
  [key in GuaranteeType]: GuaranteeDetails[]
} => {
  return {
    'partial-percentage': [
      {
        token: 'deposit_v2',
        options: {
          percentage: relative.deposit?.value,
        },
      },
      {
        token: hasIncludedExtras ? 'with-extras_v2' : 'without-extras_v2',
        options: {
          price: formatPrice(pending),
        },
      },
    ],
    'partial-days': [
      {
        token: 'deposit',
        options: {
          price: formatPrice(deposit),
        },
      },
      {
        token: hasIncludedExtras ? 'with-extras' : 'without-extras',
        options: {
          price: formatPrice(pending),
        },
      },
    ],
    'all-hotel': [
      {
        token: 'not-charge',
      },
      {
        token: 'payment-method',
      },
      {
        token: 'charge-in-hotel',
      },
    ],
    'all-prepay': [
      {
        token: 'deposit',
        options: {
          price: formatPrice(deposit),
        },
      },
    ],
  }
}

/** Claves de traducción con detalles con datos relativos y sin importes */
const buildPartialDetails = ({
  relative,
}: GuaranteePolicy): {
  [key in GuaranteeType]: GuaranteeDetails[]
} => {
  return {
    'partial-percentage': [
      {
        token:
          relative.deposit?.type === 'percentage'
            ? 'deposit-partial-percentage'
            : 'deposit-partial-days',
        options: {
          value: relative.deposit?.value,
        },
      },
      {
        token: 'pending-partial',
      },
    ],
    'partial-days': [
      {
        token:
          relative.deposit?.type === 'percentage'
            ? 'deposit-partial-percentage'
            : 'deposit-partial-days',
        options: {
          value: relative.deposit?.value,
        },
      },
      {
        token: 'pending-partial',
      },
    ],
    'all-hotel': [
      {
        token: 'not-charge',
      },
      {
        token: 'payment-method',
      },
      {
        token: 'charge-in-hotel',
      },
    ],
    'all-prepay': [
      {
        token:
          relative.deposit?.type === 'percentage'
            ? 'deposit-partial-percentage'
            : 'deposit-partial-days',
        options: {
          value: relative.deposit?.value,
        },
      },
    ],
  }
}
