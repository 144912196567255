import { ReservationGuaranteeAndCancellationTimeline } from 'src/core/Shared/domain/Reservation.model'
import { GuaranteePolicy } from 'src/core/Shared/domain/Policies.model'
import { calculateDetails } from './calculateDetails'
import { Translator } from 'src/ui/hooks/useTrans'
import { Price } from 'src/core/Shared/domain/Price.model'
import { FormatOptions } from 'src/ui/hooks/usePriceFormatter'

const TRANSLATE_CONTEXT_KEY = 'common:guarantee_policy'

export const getDetails = (
  guarantee: GuaranteePolicy,
  timeline: ReservationGuaranteeAndCancellationTimeline,
  trans: Translator,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasToShowPrices: boolean,
  hasIncludedExtras = false,
): string[] => {
  const details = calculateDetails(
    guarantee,
    formatPrice,
    hasToShowPrices,
    hasIncludedExtras,
  )
  return details.map(({ token, options }) =>
    trans(`${TRANSLATE_CONTEXT_KEY}_${timeline}_details_${token}`, options),
  )
}
