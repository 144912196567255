import {
  availableRooms,
  personalData,
  choosePayment,
  bookingConfirmation,
} from 'analytics/views'
import { useEffect, useRef } from 'react'
import { useApplicationRouter } from './useApplicationRouter'
import { launchAnalytics } from 'src/core/Shared/infrastructure/analytics/launchAnalytics'
import { ProcessType } from 'src/core/Shared/domain/Analytics'

type PageView =
  | 'availableRooms'
  | 'personalData'
  | 'choosePayment'
  | 'bookingConfirmation'

const pageViewMap: Record<
  PageView,
  (params: any, itineraryNumber?: string, process?: ProcessType) => void
> = {
  availableRooms: (params, _, process = 'reservation') =>
    availableRooms(params, process),
  personalData: (params, _, process = 'reservation') =>
    personalData(params, process),
  choosePayment: (params, _, process = 'reservation') =>
    choosePayment(params, process),
  bookingConfirmation: (params, itineraryNumber, process = 'reservation') =>
    bookingConfirmation(params, itineraryNumber as string, process),
}

export const useAnalyticsPageView = (
  pageView: PageView,
  processType: ProcessType = 'reservation',
) => {
  const { query } = useApplicationRouter()
  const retries = useRef(0)

  useEffect(() => {
    launchAnalytics(
      pageViewMap[pageView],
      [query, processType],
      'view',
      query,
      retries.current,
    )
  }, [pageView, query])
}
