import {
  CancellationPolicy,
  GuaranteePolicy,
} from 'src/core/Shared/domain/Policies.model'
import { Price } from 'src/core/Shared/domain/Price.model'
import { FormatOptions } from 'src/ui/hooks/usePriceFormatter'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

type CancellationBodyToken =
  | 'non-refundable'
  | 'free'
  | 'percentage'
  | 'days'
  | 'charge'
  | 'refund'
  | 'relative-percentage'
  | 'relative-days'
  | 'partial-percentage'

type CancellationBodyOptions = {
  hotelUTC?: string
  deadlineDay?: string
  deadlineHour?: string
  deadlineYear?: string
  percentage?: number
  days?: number
  penaltyCalculatedPrice?: string
}

export const calculateBody = (
  cancellation: CancellationPolicy,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasToShowPrices: boolean,
  guarantee?: GuaranteePolicy,
): {
  token: CancellationBodyToken
  options?: CancellationBodyOptions
} => {
  if (!cancellation.flexible || isUndefined(cancellation.deadline)) {
    return {
      token: 'non-refundable',
    }
  }

  const hotelUTC = cancellation.hotelUTC
  const deadline = cancellation.deadline
  const relativePenaltyType = cancellation.relative.penalty.type
  const relativePenaltyValue = cancellation.relative.penalty.value

  if (!cancellation.applies) {
    const hasPenalty = cancellation.penalty.value > 0
    if (!hasPenalty) {
      return { token: 'free' }
    }

    if (isDefined(guarantee) && guarantee.type.key === 'partial-percentage') {
      return {
        token: 'partial-percentage',
        options: {
          hotelUTC,
          deadlineYear: deadline.format('YYYY'),
          deadlineDay: deadline.format('D MMMM'),
          deadlineHour: deadline.format('HH:mm'),
        },
      }
    }

    return {
      token: `${relativePenaltyType}`,
      options: {
        hotelUTC,
        deadlineDay: deadline.format('D MMMM'),
        deadlineHour: deadline.format('HH:mm'),
        deadlineYear: deadline.format('YYYY'),
        [relativePenaltyType]: relativePenaltyValue,
      },
    }
  }

  if (hasToShowPrices) {
    const { calculatedCharge } = cancellation
    const { afterDeadline } = calculatedCharge
    const { value: pendingChargeValue } = afterDeadline
    const pendingToken = pendingChargeValue > 0 ? 'charge' : 'refund'

    return {
      token: pendingToken,
      options: {
        penaltyCalculatedPrice: formatPrice({
          ...afterDeadline,
          value: Math.abs(pendingChargeValue),
        }),
      },
    }
  }

  return {
    token: `relative-${relativePenaltyType}`,
    options: {
      hotelUTC,
      deadlineDay: deadline.format('D MMMM'),
      deadlineHour: deadline.format('HH:mm'),
      deadlineYear: deadline.format('YYYY'),
      [relativePenaltyType]: relativePenaltyValue,
    },
  }
}
