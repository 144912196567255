import Head from 'next/head'
import { useEffect, useState, FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  activeTitleKey: string
  inactiveTitleKey: string
}

export const TabTitle: FC<Props> = ({ activeTitleKey, inactiveTitleKey }) => {
  const { trans } = useTrans(['pages'])
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden)
    }

    setIsActive(!document.hidden)

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isActive])

  return (
    <Head>
      <title>
        {isActive ? trans(activeTitleKey) : trans(inactiveTitleKey)}
      </title>
    </Head>
  )
}
