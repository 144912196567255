import {
  CancellationPolicy,
  GuaranteePolicy,
} from 'src/core/Shared/domain/Policies.model'
import { Translator } from '../hooks/useTrans'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

const TRANSLATE_CONTEXT_KEY = 'common:cancellation_policy_title'

export const getCancellationTitle = (
  cancellation: CancellationPolicy,
  trans: Translator,
  guarantee?: GuaranteePolicy,
  withDaysRemaining = false,
): string => {
  const remainingDays = Number(cancellation.relative?.deadline?.value)

  if (cancellation.cancellationType === 'non-refundable') {
    return trans(`${TRANSLATE_CONTEXT_KEY}_non-refundable`)
  }

  if (cancellation.cancellationType !== 'free') {
    return trans(
      `${TRANSLATE_CONTEXT_KEY}_policy-${cancellation.relative.penalty.type}`,
      {
        penaltyValue: cancellation.relative.penalty.value,
      },
    )
  }

  if (isDefined(guarantee) && guarantee.type.key === 'partial-percentage') {
    return trans(`${TRANSLATE_CONTEXT_KEY}_partial-percentage`)
  }

  if (withDaysRemaining && isDefined(remainingDays) && remainingDays > 0) {
    return trans(`${TRANSLATE_CONTEXT_KEY}_free-with-days-remaining`, {
      count: remainingDays,
    })
  }

  return trans(`${TRANSLATE_CONTEXT_KEY}_free`)
}
